import { PageProps } from 'gatsby';
import { EspaceData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Admin/Form';
import IconExternalLinkAlt from '~/icons/ExternalLinkAlt';
import IconSitemap from '~/icons/Sitemap';
import params from '~/params/espace.json';
import TemplateAdmin from '~/templates/admin';
import requireUser from '~/utils/requireUser';
import useEspace from '~/utils/useEspace';

const PageAdminEspacesUpdate: FC<PageProps> = props => {
  const {
    location,
    params: { id },
  } = props;

  const [, items] = useEspace({
    id,
    pathname: location.pathname,
  });

  return (
    <TemplateAdmin>
      <CMSView>
        <Form
          docId={id}
          itemPathnamePrefix={location.pathname}
          menu={{
            right: [
              {
                icon: IconExternalLinkAlt,
                label: "Voir l'espace",
                to: `/espaces/${id}/dashboard`,
              },
              {
                icon: IconSitemap,
                label: 'Consulter les chemins',
                to: `/admin/espaces/${id}/pathnames`,
              },
            ],
          }}
          model={new EspaceData({ params })}
          name="espace"
          params={{ espaceId: id }}
          subMenu={items}
          type="update"
        />
      </CMSView>
    </TemplateAdmin>
  );
};

export default requireUser(PageAdminEspacesUpdate, 'admin');
